import './index.less';
import ModalForm from '../form';
import ModalRegister from '../form/RegisterModal';
import ModalForgotPassword from '../form/ForgotPasswordModal';

export default {

    async created() {

        if(this.$route.query && this.$route.query.next) {

            this.next = this.$route.query.next;
        }

        let captcha = document.getElementById('captcha-src');

        if (!captcha) {

            captcha = document.createElement('script');
            captcha.setAttribute('id', 'captcha-src');
            captcha.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
            document.body.appendChild(captcha);

            await new Promise((resolve, reject) => {

                captcha.onload = () => resolve();
            });
        }

        grecaptcha.ready(() => {

            if (!this.recaptcha.widgetId) {

                this.recaptcha.widgetId = grecaptcha.render("g-recaptcha", {

                    'sitekey': '6LfnLrUZAAAAAFg5Ulfl_MTPrRNjepSdwGpim3dY',
                    'callback': this.recaptchaVerifyCallback,
                    'expired-callback': this.recaptchaExpiredCallback
                });
            }

            this.loading = false;

            this.$watch(() => this.$route.hash, (value) => {

                if (value) {

                    let current = value.substring(1);

                    this.handleToggleVisible(current);
                }

            }, { immediate: true });
        });
    },
    data() {

        return {

            loading: true,
            next: null,

            recaptcha: {

                origem: null,
                widgetId: null,
                response: null
            },

            loginVisible: false,
            wasLoginVisible: false,

            registerVisible: false,
            wasRegisterVisible: false,

            forgotPasswordVisible: false,
            wasForgotPasswordVisible: false,
        }
    },

    methods: {

        handleToggleVisible(hash) {

            if (!hash) return;

            switch (hash) {
                case 'primeiro-acesso':

                    this.handleToggleRegisterVisible();
                    break;
                case 'login':

                    this.handleToggleLoginVisible();
                    break;

                case 'esqueci-senha':

                    this.handleToggleForgotPasswordVisible();
                    break;
            }
        },

        routerReplace(hash) {

            let route = {name: 'acesso'};
            if(hash) route.hash = hash;
            if(this.next) route.query = {next: this.next};
            this.$router.replace(route);
        },

        handleCancel(key) {

            if (!key) return;

            this.recaptchaExpiredCallback();

            this.routerReplace();

            this.handleToggleVisible(key);
        },

        handleOk(key) {

            if (!key) return;

            this.recaptchaExpiredCallback();

            this.handleToggleVisible(key);

            this.routerReplace('#login');
        },

        loginOk() {

            let options = undefined;
            if(this.next) {

                let resolve = this.$router.resolve(decodeURI(this.next));
                if(resolve && resolve.route) {

                    options = {redirect: resolve.route.name};
                    if(resolve.route.params) options.params = resolve.route.params;
                    if(resolve.route.query) options.query = resolve.route.query;
                    if(resolve.route.hash) options.hash = resolve.route.hash;
                }
            }
            this.$router.options.redefineRouter(options);
        },

        handleToggleLoginVisible() {

            if (!this.loginVisible && !this.wasLoginVisible) this.wasLoginVisible = true;
            this.loginVisible = !this.loginVisible;
        },

        handleToggleRegisterVisible() {

            if (!this.registerVisible && !this.wasRegisterVisible) this.wasRegisterVisible = true;
            this.registerVisible = !this.registerVisible;
        },

        handleToggleForgotPasswordVisible() {

            if (!this.forgotPasswordVisible && !this.wasForgotPasswordVisible) this.wasForgotPasswordVisible = true;
            this.forgotPasswordVisible = !this.forgotPasswordVisible;
        },

        isMobile() {

            return /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        },

        recaptchaVerifyCallback(response) {

            this.recaptcha.response = response;
        },

        recaptchaExpiredCallback() {

            this.recaptcha.origem = null;
            this.recaptcha.response = null;
            grecaptcha.reset(this.recaptcha.widgetId);
        },

        handleRecaptchaOrigin(origem) {

            if (!origem) return;
            this.recaptcha.origem = origem;
            grecaptcha.execute(this.recaptcha.widgetId);
        }
    },
    render() {

        let RegisterModal, ForgotPasswordModal, LoginModal;

        if (this.wasRegisterVisible) {

            RegisterModal = <ModalRegister
                ref="registerForm"
                visible={this.registerVisible}
                recaptcha={this.recaptcha}
                onRecaptcha={this.handleRecaptchaOrigin}
                onResetRecaptcha={() => this.recaptchaExpiredCallback()}
                onCancel={() => this.handleCancel('primeiro-acesso')}
                onOk={() => this.handleOk('primeiro-acesso')}
            />
        }

        if (this.wasForgotPasswordVisible) {

            ForgotPasswordModal = <ModalForgotPassword
                ref="forgotPasswordForm"
                visible={this.forgotPasswordVisible}
                recaptcha={this.recaptcha}
                onRecaptcha={this.handleRecaptchaOrigin}
                onResetRecaptcha={() => this.recaptchaExpiredCallback()}
                onCancel={() => this.handleCancel('esqueci-senha')}
                onOk={() => this.handleOk('esqueci-senha')}
            />
        }

        if (this.wasLoginVisible) {

            LoginModal = <ModalForm
                ref="loginForm"
                visible={this.loginVisible}
                recaptcha={this.recaptcha}
                onRecaptcha={this.handleRecaptchaOrigin}
                onResetRecaptcha={() => this.recaptchaExpiredCallback()}
                onCancel={() => this.handleCancel('login')}
                onOk={this.loginOk}
            />
        }

        return (

            <div class='wrapper'>
                <a-spin spinning={this.loading}>

                    <div id='main-login'>
                        <div class='logo-z7'>
                            <a href='https://zonasete.com.br/' target="_blank" aria-label="ZonaSete">
                                <img src={require('@/assets/logo.svg')} alt="Zona Sete" />
                            </a>

                            <ul class='social-media'>
                                <li>
                                    <a-tooltip title="Nosso perfil no Twitter">
                                        <a class="link" href='https://twitter.com/zonasete' target='_blank' aria-label='Twitter'>
                                            <g-icon icon={['fab', 'twitter']} />
                                        </a>
                                    </a-tooltip>
                                </li>
                                <li>
                                    <a-tooltip title="Nossa página no Facebook">
                                        <a class="link" href='https://www.facebook.com/azonasete' target='_blank' aria-label='Facebook'>
                                            <g-icon icon={['fab', 'facebook-f']} />
                                        </a>
                                    </a-tooltip>
                                </li>
                                <li>
                                    <a-tooltip title="Nosso perfil no Instagram">
                                        <a class="link" href='https://www.instagram.com/zona.sete' target='_blank' aria-label='instagram'>
                                            <g-icon icon={['fab', 'instagram']} />
                                        </a>
                                    </a-tooltip>
                                </li>
                                <li>
                                    <a-tooltip title="Suporte via Whatsapp">
                                        <a class="link" href={`${this.isMobile() ? 'whatsapp://send' : 'https://web.whatsapp.com/send'}?phone=5544997398072`} target='_blank' aria-label='whatsapp'>
                                            <g-icon icon={['fab', 'whatsapp']} />
                                        </a>
                                    </a-tooltip>
                                </li>
                            </ul>
                        </div>

                        <div class='welcome'>
                            <h1>Bem vindo, aqui você encontra os links para acesso e ajuda.</h1>
                        </div>
                        {
                            sayswho[0] === 'IE' && (
                                <a-alert
                                    message="Atenção"
                                    description="Este navegador é obsoleto e não é suportado, favor utilizar um mais moderno!"
                                    type="warning"
                                    showIcon
                                />
                            )
                        }
                        <div>
                            <a-row class='row-actions' type='flex' justify='center'>
                                <a-col {...{ props: { xs: 24, sm: 12, md: 7, lg: 6, xl: 5, xxl: 4 } }}>
                                    <a-card class='card-action' onClick={() => this.routerReplace('#primeiro-acesso')}>
                                        <div class='icon'>
                                            <g-icon icon={['fas', 'sign-in-alt']} size='2x' />
                                        </div>
                                        <span class='label'>Primeiro acesso</span>
                                        <p>Acesse com o código recebido</p>
                                    </a-card>
                                </a-col>

                                <a-col {...{ props: { xs: 24, sm: 12, md: 7, lg: 6, xl: 5, xxl: 4 } }}>
                                    <a-card class='card-action' onClick={() => this.routerReplace('#login')}>
                                        <div class='icon'>
                                            <g-icon icon={['fas', 'columns']} size='2x' />
                                        </div>
                                        <span class='label'>Acessar painel</span>
                                        <p>Entre no painel do cliente</p>
                                    </a-card>
                                </a-col>

                                <a-col {...{ props: { xs: 24, sm: 12, md: 7, lg: 6, xl: 5, xxl: 4 } }}>
                                    <a-card class='card-action' onClick={() => this.routerReplace('#esqueci-senha')}>
                                        <div class='icon'>
                                            <g-icon icon={['fas', 'key']} size='2x' />
                                        </div>
                                        <span class='label'>Recuperar senha</span>
                                        <p>Esqueceu a senha ?</p>
                                    </a-card>
                                </a-col>
                            </a-row>

                            <a-row class='row-actions' type='flex' justify='center'>
                                <a-col {...{ props: { xs: 24, sm: 12, md: 7, lg: 6, xl: 5, xxl: 4 } }}>
                                    <a-card class='card-action' onClick={() => window.open('https://zonasete.com.br/central-ajuda', '_blank')}>
                                        <div class='icon'>
                                            <g-icon icon={['fas', 'question-circle']} size='2x' />
                                        </div>
                                        <span class='label'>Ajuda</span>
                                        <p>Central de ajuda e suporte</p>
                                    </a-card>
                                </a-col>

                                <a-col {...{ props: { xs: 24, sm: 12, md: 7, lg: 6, xl: 5, xxl: 4 } }}>
                                    <a-card class='card-action' onClick={() => window.open('https://zonasete.com.br/contato', '_blank')}>
                                        <div class='icon'>
                                            <g-icon icon={['fas', 'user-headset']} size='2x' />
                                        </div>
                                        <span class='label'>Contato</span>
                                        <p>Entre em contato conosco</p>
                                    </a-card>
                                </a-col>
                            </a-row>
                        </div>

                        <a-row type='flex' justify='center' v-show={false}>
                            <div class='anchor' onClick={() => window.open('https://zonasete.com.br/central-ajuda', '_blank')}>
                                <g-icon icon={['fas', 'user-headset']} size='2x' />
                                <span>Ajuda</span>
                            </div>

                            <div class='anchor' onClick={() => window.open('https://zonasete.com.br/contato', '_blank')}>
                                <g-icon icon={['fas', 'user-headset']} size='2x' />
                                <span>Contato</span>
                            </div>
                        </a-row>

                        <div id="g-recaptcha" data-size="invisible"></div>


                        {LoginModal}
                        {RegisterModal}
                        {ForgotPasswordModal}

                    </div>

                </a-spin>
                <footer class='waves' />
            </div>
        )
    }
}

const sayswho = (function () {
    var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return ['IE', tem[1] || ''];
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M;
})();
