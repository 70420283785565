import http from "@/http";

export default {

    props: {

        visible: {

            type: Boolean,
            required: true
        },
        recaptcha: { type: Object }
    },
    created() {

        this.$watch('visible', (n, o) => {

            if (n) this.currentStep = 0;

        }, { immediate: false })
    },
    mounted() {

        this.unwatch = this.$watch(() => this.recaptcha.response, (val) => {

            if (val) {

                if (this.currentStep === 0) {

                    this.throwSubmitCode();
                }

                if (this.currentStep === 1) {

                    this.throwSubmit();
                }
            }
        }, { immediate: false });
    },
    beforeDestroy() {

        this.unwatch();
    },
    data() {

        return {

            data: {
                usuario: ''
            },
            user: {},
            form: {
                codigo: null,
                senha: '',
                confirmar_senha: ''
            },
            loading: false,
            passwordForce: 0,
            passwordStatus: 'exception',
            currentStep: 0,
        }
    },
    computed: {

        userMask() {
            let data = this.data.usuario;
            return data.length > 2 && /^[0-9.-]*$/.test(data) ? '###.###.###-##' : '********************';
        }
    },
    methods: {

        expiredCallback() {

            this.$emit('resetRecaptcha');
        },
        handleCancel(e) {

            if (this.loading) e.preventDefault();
            else this.$emit('cancel');
        },
        handleSubmitCode(e) {

            e.preventDefault();
            if (!this.loading) this.throwSubmitCode();
        },
        throwSubmitCode() {

            this.$refs.formRecuperaSenha1.validate(valid => {

                if (valid) {

                    if (!this.recaptcha.response) {

                        this.$emit('recaptcha', 'password');
                        return;
                    }

                    this.loading = true;

                    let data = {
                        usuario: this.data.usuario,
                        captcha: this.recaptcha.response
                    };

                    http.post('/painel/sessions.solicitarCodigoSenha', { data }).then(({ data }) => {

                        this.user = data;
                        this.currentStep = 1;

                    }).catch(e => {

                        // console.log(e);
                        this.expiredCallback();
                        this.$error({
                            title: 'Desculpe, houve um erro ao salvar!',
                            content: e.message
                        });

                    }).finally(() => this.loading = false);
                }

            });
        },
        handleSubmit(e) {

            e.preventDefault();
            if (!this.loading) this.throwSubmit();
        },
        throwSubmit() {

            this.$refs.formRecuperaSenha2.validate(valid => {

                if (valid) {

                    if (!this.recaptcha.response) {

                        this.$emit('recaptcha', 'password');
                        return;
                    }

                    this.loading = true;
                    this.form.captcha = this.recaptcha.response;

                    http.post('painel/sessions.recuperarSenha', { data: this.form }).then(({ data }) => {

                        this.currentStep = 2;

                    }).catch(e => {

                        // console.log(e);
                        if (e.statusCode === 422) {

                            this.$error({
                                title: 'Atenção',
                                content: e.message,
                            });

                        } else {

                            this.$message.error(e.message);
                        }

                    }).finally(() => {

                        this.expiredCallback();
                        this.loading = false;
                    })
                }

            });
        },
        renderStep(current) {

            switch (current) {

                case 0:
                default:
                    const userProps = {
                        ref: "formRecuperaSenha1",
                        attrs: {
                            id: `recupera-senha-form1`,
                            autocomplete: 'off'
                        },
                        props: {
                            model: this.data,
                            rules: {
                                usuario: {
                                    required: true,
                                    message: 'Por favor, informe seu usuário ou CPF!'
                                }
                            }
                        },
                        on: {
                            submit: this.handleSubmitCode
                        }
                    };

                    const maskTokens = {
                        '#': { pattern: /\d/ },
                        '*': { pattern: /./ },
                    };

                    return (
                        <div>
                            <div style="text-align: right;">
                                <a-button
                                    type="link"
                                    style="padding: 0; line-height: 2.8;"
                                    disabled={this.loading}
                                    onClick={() => this.currentStep = 1}
                                >
                                    Já possuo um código
                                </a-button>
                            </div>
                            <a-form-model
                                {...userProps}
                            >
                                <a-form-model-item
                                    prop="usuario"
                                >
                                    <span
                                        class="ant-input-search ant-input-search-enter-button ant-input-group-wrapper"
                                        style="margin-top: 12px;"
                                    >
                                        <span class="ant-input-wrapper ant-input-group">
                                            <the-mask
                                                ref="usuario"
                                                placeholder="Informe seu usuário ou CPF"
                                                mask={this.userMask}
                                                masked={true}
                                                tokens={maskTokens}
                                                class="ant-input"
                                                v-model={this.data.usuario}
                                            />
                                            <span class="ant-input-group-addon">
                                                <button type="submit" class="ant-btn ant-btn-primary ant-input-search-button">
                                                    <span>Enviar</span>
                                                </button>
                                            </span>
                                        </span>
                                    </span>
                                </a-form-model-item>
                            </a-form-model>
                        </div>
                    );
                case 1:
                    const formProps = {
                        ref: "formRecuperaSenha2",
                        attrs: {
                            id: `recupera-senha-form2`,
                            autocomplete: 'off'
                        },
                        props: {
                            model: this.form,
                            rules: {
                                codigo: {

                                    required: true,
                                    message: 'Informe o código recebido.'
                                },
                                senha: [

                                    {
                                        required: true,
                                        message: `A nova senha é obrigatória.`,
                                    },
                                    {
                                        validator: (rule, value, cb) => {

                                            if (!value) value = '';

                                            let tests = {

                                                characters: {

                                                    is: value.length >= 6 && value.length <= 30,
                                                    message: 'no mínimo 6 e no máximo 30 caracteres',
                                                    force: 15
                                                },
                                                lower: {

                                                    is: /[a-z]/.test(value),
                                                    message: 'no mínimo um caracter minúsculo',
                                                    force: 10
                                                },
                                                upper: {

                                                    is: /[A-Z]/.test(value),
                                                    message: 'no mínimo um caracter maiúsculo',
                                                    force: 25
                                                },
                                                number: {

                                                    is: /\d/.test(value),
                                                    message: 'um número',
                                                    force: 25
                                                },
                                                special: {

                                                    is: /\W/.test(value),
                                                    message: 'um caracter especial (Ex: @#$,?&%!)',
                                                    force: 25
                                                }
                                            };

                                            let msg = `A senha deve conter `;
                                            let parts = [];

                                            let force = 0;

                                            for (let k in tests) {

                                                let test = tests[k];

                                                if (test.is) {

                                                    force += test.force;

                                                } else {

                                                    parts.push(test.message);
                                                }
                                            }

                                            if (parts.length) {

                                                if (parts.length > 1) parts[parts.length - 1] = `e ${parts[parts.length - 1]}`;
                                                msg += parts.join(', ');
                                            }

                                            this.passwordForce = force;
                                            this.passwordStatus = force <= 30 ? '#f5222d' : (force <= 60 ? 'rgb(255, 85, 0)' : (force < 100 ? '#1890ff' : 'rgb(135, 208, 104)'));

                                            cb(parts.length ? msg : undefined);
                                        }
                                    }
                                ],
                                confirmar_senha: {

                                    required: true,
                                    validator: (rule, value, cb) => {

                                        if (!value) return cb('A confirmação da senha é obrigatória');
                                        cb(this.form.senha !== value ? 'As senhas não coincidem' : undefined);
                                    }
                                }
                            }
                        },
                        on: {
                            submit: this.handleSubmit
                        }
                    };
                    return (
                        <div>
                            {
                                this.user && this.user.id &&
                                <a-alert
                                    message="Atenção"
                                    type="success"
                                    showIcon
                                    style="margin-top: 12px;"
                                >
                                    <template slot="description">
                                        <strong>{this.user && this.user.nome}</strong>, um código foi enviada para o
                                        e-mail <strong>{this.user && this.user.email}</strong>, utilize-o no formulário
                                        abaixo.<br />
                                        O e-mail pode demorar alguns minutos para chegar, sempre cheque a caixa
                                        de <i>spam</i>.
                                    </template>
                                </a-alert>
                            }
                            <a-form-model
                                {...formProps}
                            >
                                <a-form-model-item

                                    label="Código"
                                    prop="codigo"
                                >
                                    <a-input

                                        allowClear
                                        placeholder="Informe o código recebido"
                                        autocomplete="off"
                                        v-model={this.form.codigo}
                                    />
                                </a-form-model-item>
                                <a-form-model-item

                                    prop="senha"
                                >
                                    <template slot="label">
                                        <span>Nova senha <a-tooltip title="Dicas para uma senha forte: substitua, por exemplo, um a por @, um e por 3, um i por !, misturando os caracteres e impedindo que sua senha seja facilmente descoberta" placement="right"><g-icon icon={["far", "question-circle"]} /></a-tooltip></span>
                                    </template>
                                    <a-input-password

                                        allowClear
                                        placeholder="Crie sua nova senha"
                                        autocomplete="off"
                                        v-model={this.form.senha}
                                    />
                                    <a-progress

                                        percent={this.passwordForce}
                                        strokeColor={this.passwordStatus}
                                        style="height: 2px;"
                                        v-show={this.form.senha}
                                    />
                                </a-form-model-item>
                                <a-form-model-item

                                    label="Confirme a nova senha"
                                    prop="confirmar_senha"
                                >
                                    <a-input-password

                                        allowClear
                                        placeholder="Confirme a senha criada"
                                        autocomplete="off"
                                        v-model={this.form.confirmar_senha}
                                    />
                                </a-form-model-item>
                                <a-button
                                    type="primary"
                                    html-type="submit"
                                    loading={this.loading}
                                    block
                                >
                                    Salvar
                                </a-button>
                            </a-form-model>
                        </div>
                    );
                case 2:
                    return (
                        <div>
                            <a-result
                                status="success"
                                title="Senha atualizada com sucesso"
                                sub-title="Sua senha foi alterada e agora você pode acessar o sistema e conferir as suas vistorias!"
                            >
                                <template slot="extra">
                                    <a-button key="console" type="primary" onClick={() => this.$emit('ok')}>
                                        Ir para o login
                                    </a-button>
                                </template>
                            </a-result>
                        </div>
                    );
            }
        }
    },
    render() {

        return (
            <a-modal
                visible={this.visible}
                //title="Recuperar minha senha"
                width="500px"
                centered
                maskClosable={!this.loading}
                footer={null}
                onCancel={(e) => this.handleCancel(e)}
            >
                <template slot='title'>

                    <g-icon icon={['fas', 'key']} style={{ marginRight: '6px' }} />

                    Recuperar minha senha
                </template>
                <a-steps progress-dot current={this.currentStep}>
                    <a-step title="Usuário" subTitle="Identifique-se" />
                    <a-step title="Senha" subTitle="Crie sua nova senha" />
                    <a-step title="Feito" subTitle="Acesse o painel" />
                </a-steps>

                <div>
                    {
                        this.renderStep(this.currentStep)
                    }
                </div>

                <div v-show={this.loading} class="loading-modal">
                    <a-spin size="large" />
                </div>
            </a-modal>
        );
    }
}
