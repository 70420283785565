import { isCpf, isEmail, isEmpty, isCnpj } from "@/resources/util";
import http from "@/http";
import moment from "moment";
import ModalTerms from "@/components/modal-terms/modalTerms";

export default {

    props: {

        visible: {

            type: Boolean,
            required: true
        },
        recaptcha: { type: Object }
    },
    mounted() {

        this.unwatch = this.$watch(() => this.recaptcha.response, (val) => {

            if (val) {

                if (this.currentStep === 0) {

                    this.throwSubmitCode();
                }

                if (this.currentStep === 1) {

                    this.throwRegister();
                }
            }
        }, { immediate: false });
    },
    beforeDestroy() {

        this.unwatch();
    },
    data() {

        return {

            data: {

                nome: null,
                ator_id: null,
                cpf: null,
                cnpj: null,
                data_nascimento: null,
                email: null,
                telefone: null,
                usuario: null,
                senha: null,
                confirmar_senha: null,
                captcha: null
            },
            rules: {
                nome: [
                    {
                        required: true,
                        message: 'Por favor, informe seu nome.'
                    },
                    {
                        min: 4,
                        message: 'Informe um nome válido.'
                    },
                    {
                        validator: (rule, value, cb) => cb(!isEmpty(value) && value.length >= 4 && value.trim().split(" ").length < 2 ? 'Informe o nome e sobrenome corretamente' : undefined)
                    }
                ],
                cpf: {
                    required: true,
                    validator: (rule, value, cb) => cb(!isEmpty(value) ? (isCpf(value) ? undefined : `Informe um CPF válido.`) : `Por favor, informe seu CPF!`)
                },
                cnpj: {
                    required: true,
                    validator: (rule, value, cb) => cb(!isEmpty(value) ? (isCnpj(value) ? undefined : `Informe um CNPJ válido.`) : `Por favor, informe seu CNPJ!`)
                },
                data_nascimento: {
                    required: true,
                    message: 'Por favor, informe sua data de nascimento!'
                },
                email: {
                    required: true,
                    validator: (rule, value, cb) => cb(!isEmpty(value) ? (isEmail(value) ? undefined : `Informe um E-mail válido!`) : `Por favor, informe seu e-mail!`)
                },
                telefone: {
                    required: true,
                    message: 'Por favor, informe o número do seu telefone!'
                },
                usuario: [
                    {
                        required: true,
                        message: 'Por favor, informe seu usuário!'
                    },
                    {
                        min: 4,
                        max: 20,
                        message: 'Por favor, informe pelo menos 4 e no máximo 20 caracteres'
                    },
                    {
                        pattern: /^[a-z0-9_.]*$/,
                        message: 'Utilize apenas letras minúsculas, números e/ou os caracteres ._-'
                    },
                    {
                        pattern: /[a-z]/i,
                        message: 'Por favor, informe pelo menos uma letra.'
                    },
                    {
                        asyncValidator: (rule, value, cb) => {

                            if (/[._]{2,}/.test(value)) {

                                cb('Não pode haver pontos ou underlines ._ consecutivos.');

                            } else if (value.charAt(0) === '.' || value.charAt(value.length - 1) === '.') {

                                cb('O usuário deve começar e terminar apenas com uma letra, número ou underline.');

                            } else {

                                http.get(`painel/sessions.checarUsuario?usuario=${value}`).then(({ data }) => {

                                    if (data.length) cb('Usuário já existe, por favor escolha outro');
                                    else cb();
                                }).catch(e => cb());
                            }
                        }
                    }
                ],
                senha: [

                    {
                        required: true,
                        message: `Por favor, informe sua senha!`
                    },
                    {
                        validator: (rule, value, cb) => {

                            if (!value) value = '';

                            let tests = {

                                characters: {

                                    is: value.length >= 6 && value.length <= 30,
                                    message: 'no mínimo 6 e no máximo 30 caracteres',
                                    force: 15
                                },
                                lower: {

                                    is: /[a-z]/.test(value),
                                    message: 'no mínimo um caracter minúsculo',
                                    force: 10
                                },
                                upper: {

                                    is: /[A-Z]/.test(value),
                                    message: 'no mínimo um caracter maiúsculo',
                                    force: 25
                                },
                                number: {

                                    is: /\d/.test(value),
                                    message: 'um número',
                                    force: 25
                                },
                                special: {

                                    is: /\W/.test(value),
                                    message: 'um caracter especial (Ex: @#$,?&%!)',
                                    force: 25
                                }
                            };

                            let msg = `A senha deve conter `;
                            let parts = [];

                            let force = 0;

                            for (let k in tests) {

                                let test = tests[k];

                                if (test.is) {

                                    force += test.force;

                                } else {

                                    parts.push(test.message);
                                }
                            }

                            if (parts.length) {

                                if (parts.length > 1) parts[parts.length - 1] = `e ${parts[parts.length - 1]}`;
                                msg += parts.join(', ');
                            }

                            this.passwordForce = force;
                            this.passwordStatus = force <= 30 ? '#f5222d' : (force <= 60 ? 'rgb(255, 85, 0)' : (force < 100 ? '#1890ff' : 'rgb(135, 208, 104)'));

                            cb(parts.length ? msg : undefined);
                        }
                    }
                ],
                confirmar_senha: {

                    required: true,
                    validator: (rule, value, cb) => {

                        if (!value) return cb('A confirmação da senha é obrigatória');
                        cb(this.data.senha !== value ? 'As senhas não coincidem' : undefined);
                    }
                }
            },
            ator: {},
            termosModal: false,
            aceite: false,
            codeHasError: false,
            currentStep: 0,
            passwordForce: 0,
            passwordStatus: 'exception',
            loading: false,
        }
    },
    methods: {

        generateRandomUsernames(name) {

            if (isEmpty(name) || name.length < 4 || (name || "").split(" ").length < 2) return;

            this.loading = true;

            return http.get(`/cadastro.gerarUsername?name=${name}`).then(({ data }) => {

                if (data && data.username) this.data.usuario = data.username;

            }).catch(err => {

                console.log(err);

            }).finally(() => this.loading = false);
        },

        handleSubmitCode(data) {

            if (!data.length) {

                this.codeHasError = true;
                return;
            }

            this.codeSubmited = data;

            if (!this.loading) this.throwSubmitCode();

        },

        throwSubmitCode() {

            if (!this.codeSubmited) return;

            if (!this.recaptcha.response) {

                this.$emit('recaptcha', 'register');
                return;
            }

            this.loading = true;

            const data = {
                codigo: this.codeSubmited,
                captcha: this.recaptcha.response
            }

            http.post(`painel/sessions.checarCodigo`, { data }).then(({ data }) => {

                this.ator = data;
                this.data.ator_id = data.ator_id;
                this.currentStep = 1;

            }).catch(e => {

                // console.log(e);
                this.$message.error(e.message);

            }).finally(() => {

                if (this.ator.nome) {

                    this.generateRandomUsernames(this.ator.nome);
                }

                this.expiredCallback();
                this.loading = false;
            });
        },

        handleCancel(e) {

            if (this.loading) e.preventDefault();
            else this.$emit('cancel');
        },

        handleSubmit(e) {

            e.preventDefault();
            if (!this.loading) this.throwRegister();
        },

        expiredCallback() {

            this.$emit('resetRecaptcha');
        },

        throwRegister() {

            this.$refs.formRegister.validate(valid => {

                if (!valid) return;

                if (!this.recaptcha.response) {

                    this.$emit('recaptcha', 'register');
                    return;
                }

                this.loading = true;
                this.data.captcha = this.recaptcha.response;

                let user = { ...this.data };

                if (this.ator.pessoa === 'J') {

                    user.documento = this.data.cnpj;

                } else {

                    user.documento = this.data.cpf;
                    delete user.nome;
                }

                delete user.cnpj;

                http.post('painel/sessions.salvarUsuario', { data: user }).then(({ data }) => {

                    this.currentStep = 2;

                }).catch(e => {

                    // console.log(e);
                    if (e.statusCode === 422 || e.statusCode === 500) {

                        let message;

                        if (!e.message.includes('Duplicate')) message = e.message;

                        else {

                            const key = e.message.split(" ").pop().replace(/'/g, '').toUpperCase();

                            message = `Já existe um usuário com esse ${key}.`
                        }

                        this.$error({
                            title: 'Atenção',
                            content: message,
                        });

                    } else {

                        this.$message.error(e.message);
                    }

                }).finally(() => {

                    this.expiredCallback();
                    this.loading = false;
                })
            });
        },

        disabledDate(current) {

            return current._d && current._d > moment().endOf('day');
        },

        handleTerm(val) {

            this.aceite = val;
            this.termosModal = false;
        },

        renderStep(current) {

            switch (current) {

                case 0:
                default:
                    return (
                        <div>
                            <a-alert
                                message="Atenção"
                                description="Para se registrar, você precisa ter sido pré-cadastrado como parte envolvida de algum imóvel do sistema Zona Sete Vistorias. Digite abaixo o código que você recebeu por SMS ou em seu e-mail."
                                type="info"
                                showIcon
                            />
                            <a-form-model>
                                <a-form-model-item>
                                    <a-input-search
                                        placeholder="Digite o código"
                                        enter-button="Enviar"
                                        onChange={e => this.codeHasError = !e.target.value || e.target.value.length < 4}
                                        onSearch={this.handleSubmitCode}
                                        style="margin-top: 12px;"
                                        class={{ 'has-error': this.codeHasError }}
                                    />
                                    <div v-show={this.codeHasError} class="has-error">
                                        <span class="ant-form-explain">Por favor, informe seu código recebido!</span>
                                    </div>
                                </a-form-model-item>
                            </a-form-model>
                        </div>
                    );
                case 1:
                    const formProps = {
                        ref: "formRegister",
                        attrs: {
                            id: `register-form`,
                            autocomplete: 'off'
                        },
                        props: {
                            model: this.data,
                            rules: this.rules
                        },
                        on: {
                            submit: this.handleSubmit
                        }
                    };

                    return (
                        <div>
                            <a-alert
                                message="Atenção"
                                type="success"
                                showIcon
                                style="margin-top: 12px;"
                            >
                                <template slot="description">
                                    Olá <strong>{this.ator.nome}</strong>, seu código foi encontrado, ele foi registrado
                                    por <strong>{this.ator.organizacao}</strong>, agora preencha seus dados abaixo, eles
                                    devem estar de acordo com os fornecidos para a empresa anteriormente, e crie um
                                    usuário e senha para que você sempre tenha acesso às suas vistorias.
                                </template>
                            </a-alert>
                            <a-form-model
                                {...formProps}
                            >
                                {this.ator.pessoa === 'J' && (

                                    <div>
                                        <a-form-model-item
                                            label="CNPJ"
                                            prop="cnpj"
                                        >
                                            <a-input key="cnpj"
                                                allowClear
                                                placeholder="Informe o CNPJ da empresa"
                                                v-model={this.data.cnpj}
                                                v-mask={'##.###.###/####-##'}
                                            />
                                        </a-form-model-item>

                                        <a-form-model-item
                                            label="Nome completo"
                                            prop="nome"
                                        >
                                            <a-input key="nome"
                                                allowClear
                                                placeholder="Informe seu nome completo"
                                                v-model={this.data.nome}
                                                onBlur={e => this.generateRandomUsernames(e.target.value)}
                                            />
                                        </a-form-model-item>
                                    </div>
                                )}

                                <a-form-model-item
                                    label="CPF"
                                    prop="cpf"
                                >
                                    <a-input key="cpf"
                                        allowClear
                                        placeholder="Informe seu CPF"
                                        v-model={this.data.cpf}
                                        v-mask={'###.###.###-##'}
                                    />
                                </a-form-model-item>
                                <a-form-model-item
                                    label="Data de Nascimento"
                                    prop="data_nascimento"
                                >
                                    <a-date-picker
                                        allowClear
                                        v-ant-picker-mask
                                        placeholder="Selecione a data"
                                        format="DD/MM/YYYY"
                                        valueFormat="YYYY-MM-DD"
                                        showToday={false}
                                        disabledDate={this.disabledDate}
                                        v-model={this.data.data_nascimento}
                                    />
                                </a-form-model-item>
                                <a-form-model-item
                                    label="E-mail"
                                    prop="email"
                                >
                                    <a-input key="email"
                                        type="email"
                                        allowClear
                                        placeholder="Informe seu E-mail"
                                        v-model={this.data.email}
                                    />
                                </a-form-model-item>
                                <a-form-model-item
                                    label="Telefone"
                                    prop="telefone"
                                >
                                    <a-input key="telefone"
                                        allowClear
                                        placeholder="Informe seu Telefone"
                                        v-model={this.data.telefone}
                                        v-mask={['(##) ####-####', '(##) #####-####']}
                                    />
                                </a-form-model-item>
                                <a-form-model-item
                                    prop="usuario"
                                >
                                    <template slot="label">
                                        <span>Usuário <a-tooltip title="Utilize apenas letras minúsculas, números e os caracteres . _" placement="right"><g-icon icon={["far", "question-circle"]} /></a-tooltip></span>
                                    </template>
                                    <a-input key="usuario"
                                        allowClear
                                        placeholder="Crie seu usuário"
                                        v-model={this.data.usuario}
                                    />
                                </a-form-model-item>
                                <a-form-model-item
                                    prop="senha"
                                >
                                    <template slot="label">
                                        <span>Senha <a-tooltip title="Dicas para uma senha forte: substitua, por exemplo, um a por @, um e por 3, um i por !, misturando os caracteres e impedindo que sua senha seja facilmente descoberta" placement="right"><g-icon icon={["far", "question-circle"]} /></a-tooltip></span>
                                    </template>
                                    <a-input-password
                                        allowClear
                                        autocomplete="off"
                                        placeholder="Crie sua Senha"
                                        v-model={this.data.senha}
                                    />
                                    <a-progress

                                        percent={this.passwordForce}
                                        strokeColor={this.passwordStatus}
                                        style="height: 2px;"
                                        v-show={this.data.senha}
                                    />
                                </a-form-model-item>
                                <a-form-model-item

                                    label="Confirme a nova senha"
                                    prop="confirmar_senha"
                                >
                                    <a-input-password

                                        allowClear
                                        placeholder="Confirme a senha criada"
                                        autocomplete="off"
                                        v-model={this.data.confirmar_senha}
                                    />
                                </a-form-model-item>
                                <a-button type="link" onClick={() => this.termosModal = true}>
                                    <g-icon icon={['far', this.aceite ? 'check-square' : 'square']} />
                                    Eu li e concordo com os termos de serviço
                                </a-button>
                                <a-button
                                    type="primary"
                                    html-type="submit"
                                    loading={this.loading}
                                    disabled={!this.aceite}
                                    block
                                >
                                    Salvar
                                </a-button>
                            </a-form-model>
                            <ModalTerms
                                visible={this.termosModal}
                                onAcceptTerm={(val) => this.handleTerm(val)}
                            />
                        </div>
                    );
                case 2:
                    return (
                        <div>
                            <a-result
                                status="success"
                                title="Cadastro realizado com sucesso"
                                sub-title="Sua conta foi criada e agora você pode acessar o sistema e conferir as suas vistorias!"
                            >
                                <template slot="extra">
                                    <a-button key="console" type="primary" onClick={() => this.$emit('ok')}>
                                        Ir para o login
                                    </a-button>
                                </template>
                            </a-result>
                        </div>
                    );

            }
        }
    },
    render() {

        return (
            <a-modal
                visible={this.visible}
                //title="Primeiro acesso"
                width="500px"
                centered
                maskClosable={!this.loading}
                footer={null}
                onCancel={(e) => this.handleCancel(e)}
            >
                <template slot='title'>

                    <g-icon icon={['fas', 'sign-in-alt']} style={{ marginRight: '6px' }} />

                    Primeiro acesso
                </template>
                <a-steps progress-dot current={this.currentStep}>
                    <a-step title="Código" subTitle="Informe o código recebido" />
                    <a-step title="Cadastro" subTitle="Crie sua conta" />
                    <a-step title="Feito" subTitle="Acesse o painel" />
                </a-steps>

                <div>
                    {
                        this.renderStep(this.currentStep)
                    }
                </div>

                <div v-show={this.loading} class="loading-modal">
                    <a-spin size="large" />
                </div>

            </a-modal>
        );
    }
}
