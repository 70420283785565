export default {

    props: {

        visible: {

            type: Boolean,
            required: true
        },
        recaptcha: { type: Object }
    },
    mounted() {

        this.unwatch = this.$watch(() => this.recaptcha.response, (val) => {

            if (val) {

                this.throwLogin();
            }
        }, { immediate: false });
    },
    beforeDestroy() {

        this.unwatch();
    },
    data() {

        return {

            loading: false,
            data: {
                usuario: null,
                senha: null
            },
            rules: {
                usuario: {

                    required: true,
                    validator: (rule, value, cb) => {

                        if (!value) return cb('Por favor, informe seu usuário, e-mail ou CPF!');

                        if (/\s/g.test(value)) return cb('Por favor informe um usuário válido.');

                        cb(undefined);
                    }
                },
                senha: {
                    required: true,
                    message: 'Por favor, informe sua senha!'
                }
            }
        }
    },
    methods: {

        expiredRecaptcha() {

            this.$emit('resetRecaptcha');
        },

        handleCancel(e) {

            if (this.loading) e.preventDefault();
            else {

                this.$refs.form.resetFields();

                this.$emit('cancel');
            }
        },

        throwLogin() {

            this.$refs.form.validate(valid => {

                if (valid) {

                    if (!this.recaptcha.response) {

                        this.$emit('recaptcha', 'login');
                        return;
                    }

                    this.loading = true;

                    const data = {
                        usuario: this.data.usuario,
                        senha: this.data.senha,
                        captcha: this.recaptcha.response
                    }

                    this.$store.dispatch('session/login', { data }).then(result => {

                        this.$emit('ok');

                    }, (e) => {

                        // console.log(e)
                        this.$error({

                            title: 'Atenção',
                            content: !e.error ? e.message : 'Erro ao efetuar o login, verifique os dados informados!',
                            maskClosable: true
                        });

                    }).finally(() => {

                        this.expiredRecaptcha();
                        this.loading = false;
                    });
                }
            });
        },
        handleSubmit(e) {

            e.preventDefault();
            if (!this.loading) this.throwLogin();
        }
    },
    render() {

        const formProps = {
            ref: "form",
            attrs: {
                id: `login-form`,
                class: 'login-form',
                autocomplete: 'off'
            },
            props: {
                model: this.data,
                rules: this.rules
            },
            on: {
                submit: this.handleSubmit
            }
        };

        return (

            <a-modal
                visible={this.visible}
                // title="Login"
                width="400px"
                centered
                footer={null}
                onCancel={(e) => this.handleCancel(e)}
            >

                <template slot='title'>

                    <g-icon icon={['fas', 'columns']} style={{ marginRight: '6px' }} />

                    Login - Painel do cliente
                </template>
                <a-spin spinning={this.loading}>
                    <a-form-model {...formProps}>
                        <a-form-model-item prop="usuario">
                            <a-input
                                ref="usuario"
                                allowClear
                                placeholder="Nome de usuário, e-mail ou CPF"
                                size="large"
                                maxLength={100}
                                v-model={this.data.usuario}
                            >
                                <g-icon slot="prefix" icon={['far', 'user']} style="color: rgba(0,0,0,.25)" />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item prop="senha">
                            <a-input-password
                                ref="senha"
                                allowClear
                                placeholder="Senha"
                                autocomplete="on"
                                size="large"
                                maxLength={20}
                                v-model={this.data.senha}
                            >
                                <g-icon slot="prefix" icon={['far', 'lock-alt']} style="color: rgba(0,0,0,.25)" />
                            </a-input-password>
                        </a-form-model-item>
                        <a-form-model-item>

                            <a-button
                                type="primary"
                                html-type="submit"
                                size="large"
                                loading={this.loading}
                                block
                            >
                                Login
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                </a-spin>
            </a-modal>
        );
    }
}